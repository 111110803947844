/* Card CSS data goes here */
* {
    --primary-red: rgb(155,31,34);
    --primary-brown: rgb(123, 107, 93);
    --primary-white: rgb(255,255,255);

}


div.header {
    height: 150px;
    width: 100%;
    background: var(--primary-red);
    margin-bottom: 20px;
}

.title{
    width: 100%;
}

.bannerImg {
    width: 70%;
    margin-bottom: 20px;
}

.logo {

    width: 110px;
    float: left;
    margin-left: 70px;
    margin-right: 30px;
    margin-bottom: 10px;
}

.donateBtn {
    background-color: var(--primary-brown);
    border: 3px solid var(--primary-white);
    border-radius: 12px;
    color: white;
    padding: 15px 35px;
    text-align: right;
    margin-right: 100px;
    margin-top: 40px;
    display: inline-block;
    font-size: 26px;
    font-family: Garamond, serif;
    font-weight: 600;
}

.donateBtn:hover {
    box-shadow: 0px 0px 10px var(--primary-white);
    cursor: pointer;

}

div.buttonNav {
    float: right;
}


.titleUnderline {
    width: 50%;
    align-items: center;
}

.titleName {
    font-size: 35px;
    font-family: Garamond, serif;

}

.titleNameHis {
    font-size: 50px;
    font-family: Garamond, serif;

}

.projectGoal {
    font-family: Garamond, serif;
    font-size: 20px;
    font-weight: 540;
    color: #7B6B5D;
}

.projectSection {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    text-align: center;
}


.parkletImg {
    flex: 1;
    margin-left: 50px;
    
}

.parkletPic {
    width: 600px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
    margin-right: 20px;

}

.projectInfo {
    width: 800px;
    margin-left: 20px;
}

.cardHolder {
    display: flex;
    margin-bottom: 35px;
}

hr { 
    width: 80%;
    align-items: center;
    border-width: 2px;
}

.card {
    flex: 2;
    font-family: Garamond, serif;
    border-radius: 30px;
    box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.5);
    border: 4px solid var(--primary-red);
    padding-bottom: 35px;
    margin-left: 30px;
    margin-right: 30px;
    height: 530px;

}

.card:hover {
    box-shadow: 0px 0px 35px rgba(0, 0, 0, 2);

}

.cardDescription {
    font-size: 30px;
    font-weight: 550;
}

.cardText {
    font-size: 20px;
    font-weight: 550;
    color: #7B6B5D;
}

.cardImg {
    margin-top: 20px;
    border-radius: 20%;
}

.footerSection {
    display: flex;
    background-color: var(--primary-red);
}

.leftFooter {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
}

.links {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    padding-left: 0px;
}

.twitter {
    width: 100px;
    fill: var(--primary-white);
}

.instagram {
    flex: 1;
    width: 100px;
}

li {
    list-style: none;
}

.birdImg {
    height: 600px;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-right: 40px;
}

.footerTitle {
    color: var(--primary-white);
    font-family: Garamond, serif;
    font-size: 35px;
    flex: 1;
}

.footerText {
    color: var(--primary-white);
    font-family: Garamond, serif;
    font-size: 25px;
    justify-content: center;
    margin-left: 40px;
    margin-right: 40px;
    flex: 2;
    text-align: center;
}

.footerBtn {
    background-color: var(--primary-brown);
    border: 3px solid var(--primary-white);
    border-radius: 12px;
    color: white;
    padding: 15px 35px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: inline-block;
    font-size: 26px;
    font-family: Garamond, serif;
    font-weight: 600;
    flex: 3;
    width: 250px;

}

.footerBtn:hover {
    box-shadow: 0px 0px 10px var(--primary-white);
    cursor: pointer;
}

.contactInfo {
    /* !!! TODO TOUCH UP CONTACT INFO !!! */
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    flex: 4;
    /* !!! TODO FIX ALIGNMENT !!! */
}
.addressInfo {
    color: var(--primary-white);
    font-size: 25px;
    font-family: Garamond, serif;
    margin-top: 20px;
    margin-right: 18px;
}



a.email {
    color: var(--primary-white);
    font-size: 25px;
    margin-left: 18px;
    margin-top: 20px;
    font-family: Garamond, serif;
    text-align: center;
}

a.linkcolor {
    color: var(--primary-red);
}

/* Styles for iPhones */
@media only screen and (max-width: 480px) {
    .logo {

        width: 70px;
        float: left;
        margin-left: 40px;
        margin-right: 30px;
        margin-bottom: 10px;
    }

    .donateBtn {
        background-color: var(--primary-brown);
        border: 3px solid var(--primary-white);
        border-radius: 12px;
        color: white;
        padding: 10px 30px;
        text-align: right;
        margin-right: 50px;
        margin-top: 20px;
        display: inline-block;
        font-size: 18px;
        font-family: Garamond, serif;
        font-weight: 600;
    }

    div.header {
        height: 110px;
        width: 100%;
        background: var(--primary-red);
        margin-bottom: 20px;
    }

    .parkletPic {
        width: 300px;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
        justify-content: center;
    
    }

    .projectSection {
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        text-align: center;
        align-items: center;
        flex-direction: column;
        margin-right: 8%;
    }
    .projectInfo {
        width: 300px;
        margin-left: 15px;
    }
    .titleNameHis {
        font-size: 30px;
        font-family: Garamond, serif;
    
    }
    
    .projectGoal {
        font-family: Garamond, serif;
        font-size: 20px;
        font-weight: 540;
        color: #7B6B5D;
    }
    .cardHolder {
        display: flex;
        margin-bottom: 35px;
        flex-direction: column;
    }
    .links {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
        padding-left: 0px;
    }
    .addressInfo {
        color: var(--primary-white);
        font-size: 15px;
        font-family: Garamond, serif;
        margin-top: 20px;
        margin-right: 5px;
    }
    

    .twitter {
        width: 50px;
        fill: var(--primary-white);
    }
    
    .instagram {
        flex: 1;
        width: 50px;
    }
    .footerBtn {
        background-color: var(--primary-brown);
        border: 3px solid var(--primary-white);
        border-radius: 12px;
        color: white;
        padding: 7px 10px;
        margin-top: 20px;
        margin-bottom: 20px;
        display: inline-block;
        font-size: 15px;
        font-family: Garamond, serif;
        font-weight: 600;
        width: 150px;
        height: 40px;
    
    }
    .footerText {
        color: var(--primary-white);
        font-family: Garamond, serif;
        font-size: 13px;
        justify-content: center;
        margin-left: 3%;
        margin-right: 3%;
        flex: 2;
        text-align: center;
    }
    .footerTitle {
        color: var(--primary-white);
        font-family: Garamond, serif;
        font-size: 17px;
        flex: 1;
        text-align: center;
        margin-left: 2%;
    }
    a.email {
        color: var(--primary-white);
        font-family: Garamond, serif;
        font-size: 15px;
        text-align: center;
        margin-bottom: 20px;
    }
    .birdImg {
        height: 130px;
        margin-top: 50px;
        margin-bottom: 50px;
        margin-left: 12%;
        display: inline-block;
    }
    .footerSection {
        display: flex;
        background-color: var(--primary-red);
        align-items: center;
    }
    
    
      }
  
  /* Styles for iPhone 6/7/8 and iPhone X */
  @media only screen and (min-width: 375px) and (max-width: 812px) {
    /* Add your styles here */
  }
  
  /* Styles for iPhone 6/7/8 Plus */
  @media only screen and (min-width: 414px) and (max-width: 736px) {
    /* Add your styles here */
  }
  
  /* Styles for iPhone 11 Pro, iPhone XS, and iPhone X */
  @media only screen and (min-width: 375px) and (max-width: 812px) {
    /* Add your styles here */
  }
  
  /* Styles for iPhone 11, iPhone XR */
  @media only screen and (min-width: 414px) and (max-width: 896px) {
    /* Add your styles here */
  }
  