/* Header CSS data goes here */

* {
    --primary-red: rgb(155,31,34);
    --primary-brown: rgb(123, 107, 93);
    --primary-white: rgb(255,255,255);

}


.donateSection {
    display: flex;
    justify-content: space-around;
    

}
.donationCard {
    flex-direction: row;
    
}

.cardContainer {
    display: flex;

}

.card {
    flex-direction: column;
    align-content: center;
    justify-self: center;
    margin-left: 5%;
    margin-right: 5%;
}

.cardText {
    font-family: Garamond, serif;
    text-align: center;
    justify-content: center;
    margin-left: 5%;
    margin-right: 5%;
    font-size: 20px;
    font-weight: bold;
    color: var(--primary-brown);
}

.cardDescription {
    justify-content: center;
    align-content: center;
}

h1 {
    font-size: 50px;
    font-family: Garamond, serif;
    text-align: center;
}

.donationTitle {
    font-family: Garamond, serif;
    margin-left: 25%;
    margin-right: 25%;
    text-align: center;
    font-size: 25px;
    font-weight: 1000;
}

.cardImg {
    align-content: center;
    justify-content: center;
    margin-left: 25%;
    margin-right: 25%;
    width: 50%;
    margin-bottom: 25px;
}

hr {
    align-items: center;
    width: 30%;
}


.cardImg:hover {
    width: 53%;
    cursor: pointer;

}

/* Styles for iPhones */
@media only screen and (max-width: 480px) {
    .cardText {
        font-family: Garamond, serif;
        text-align: center;
        justify-content: center;
        margin-left: 5%;
        margin-right: 5%;
        font-size: 13px;
        font-weight: bold;
        color: var(--primary-brown);
    }

    .donationTitle {
        font-family: Garamond, serif;
        margin-left: 25%;
        margin-right: 25%;
        text-align: center;
        font-size: 15px;
        font-weight: 1000;
    }

    .cardImg {
        align-content: center;
        justify-content: center;
        margin-left: 25%;
        margin-right: 25%;
        width: 50%;
        margin-bottom: 10px;
    }
    h1 {
        font-size: 30px;
        font-family: Garamond, serif;
        text-align: center;
    }
    .donateSection {
        display: flex;
        justify-content: space-around;
        align-items: center;
    
    }
    

}